import { graphql } from 'gatsby';
import Template from '../template';

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    article: strapiBlogArticleTranslation(id: {eq: $id}) {
      ...DetailArticleFragment
    }
    labelsAndMessages {
      ar
    }
  }
`;
